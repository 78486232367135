<template>
  <div class="bg">
    <section class="banner">
      <img class="bannerImg" src="../../assets/img/openness/openness-banner.png" alt="" width="100%">
      <div class="banner-block">
        <div class="title">
          <h1 class="banner-title hidden-xs-only">开放能力</h1>
          <svg-icon class="banner-line-img hidden-xs-only" svg-name="title-white"></svg-icon>
          <h2 class="banner-sutitle hidden-xs-only">为了让开发者可以使用更多的系统能力，并降低接入成本，我们制定了联盟开放能力的统一标准。</h2>
        </div>
      </div>
    </section>
    <section class="row-block3">
      <el-row>
        <el-col :xs="{span:24,offset:0}" :span="18" :offset="3">
          <section class="text-center">
            <h3 class="section-title">能力说明</h3>
            <svg-icon class="section-img hidden-xs-only" svg-name="title-white"></svg-icon>
          </section>
        </el-col>
      </el-row>
      <ul class="abilityBox">
        <li class="item" v-for="(item,index) in this.data" :key="index">
          <div class="imgBox">
            <img :src="item.img" alt="">
          </div>
          <div class="textBox">
            <h1 class="title">{{item.title}}</h1>
            <p class="text">{{item.text}}</p>
            <a :href="item.url" target="_blank"><div class="button" @click="changes(index)">立即查看</div></a>
          </div>
        </li>
      </ul>
    </section>
    <section class="footContentBox">
      <p class="footcontentTitle">告诉我们您希望开放的其他能力</p>
      <a @click="mailsome1()" href="mailto:service@itgsa.com" class="contactBox">
        <p>联盟邮箱</p>
        <p>service@itgsa.com</p>
      </a>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      num:0,
      data:[
        {
          img:require("@/assets/img/openness/k.png"),
          title:"K歌耳返",
          text:'录音K歌时实现耳机返听，同时提供多种混响和均衡效果。',
          url:"http://39.106.70.232:41180/gsaidemo/audiodemo"
        },
        {
          img:require("@/assets/img/openness/roi.png"),
          title:"ROI编码",
          text:'支持调用系统编码器的ROI功能，提升直播、视频通话场景中局部区域的清晰度。',
          url:"http://39.106.70.232:41180/gsaidemo/roiencodedemo"
        },
        {
          img:require("@/assets/img/openness/4D.png"),
          title:"4D震感",
          text:'支持多种线性马达波形震感，丰富应用体验。',
          url:"http://gitlab.itgsa.com:41180/gsaidemo/hapticdemo"
        },
      ]
    }
  },
  methods:{
    changes(key){
      this.num=key;
    },
  }
}
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.bg {
  .banner {
    position: relative;
    width: 100%;
    min-height: 300px;
    .bannerImg{
      width: 100%;
      min-height: 300px;
    }
  }
}

.banner-block {
  position: absolute;
  height: 100%;
  transform: translateX(-50%);
  bottom: 0;
  left: 50%;
  width: 946px;

  .title {
    height: 75%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .banner-block {
    width: 100%;
  }
}

.banner-line-img {
  width: 382px;
  height: 27px;
}

.banner-title {
  font-size: 48px;
  line-height: 100px;
  text-align: center;
  font-weight: 900;
  color: #f2e9c1;
}

.banner-sutitle {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  color: rgba(242, 233, 193, 1);
  line-height: 20px;
}

.banner-button {
  position: absolute;
  font-size: 18px;
  transform: translateX(-50%);
  bottom: 10%;
  left: 50%;
  width: 203px;
  height: 60px;
  cursor: pointer;
}

.section-title {
  margin-top: 40px;
  font-size: 36px;
  font-weight: bold;
  color: #c3ba93;
}

.section-img {
  width: 882px;
  height: 37px;
  margin-top: 20px;
}

.row-block3 {
  margin-top: 40px;
  width: 100%;
  height:860px;
  .abilityBox{
    width: 1300px;
    margin: 67px auto 0;
    display: flex;
    justify-content: space-around;
    .item{
      width: 384px;
      height: 504px;
      background: #FFFFFF;    
      overflow: hidden;
      border-radius:10px;
      .imgBox{
        width: 100%;
        height: 281px;
        img{
          width: 100%;
        }
      }
      .textBox{
        padding: 32px 35px 0 24px;
        height: 223px;
        position: relative;
        border: 1px solid #E1E1E1;
        border-top: none;
        border-radius:0 0 10px 10px;
        .title{
          font-size: 24px;
          font-weight: bold;
          color: #333333;
        }
        .text{
          margin-top: 17px;
          font-size: 16px;
          color: #6A6A6A;
          line-height: 26px;
        }
        .button{
          position: absolute;
          width: 143px;
          height: 44px;
          border: 2px solid #333333;
          border-radius: 22px;
          line-height: 39px;
          text-align: center;
          font-size: 16px;
          bottom: 30px;
        }
        .button:hover{ 
          background: #333333;
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .row-block3 {
    height: 1100px;
  }
}
.contactBox{
  margin: 30px auto 0px;
  width: 216px;
  height: 59px;
  border: 2px solid #000000;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  flex-direction:column;
  align-items: center;
  p{ 
    color: #202020;
    font-size: 16px;
  }
}
</style>